.course-card {
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  .course-card {
    padding: 0 !important; /* Remove any padding */
    margin: 0; /* Ensure no extra margin */
    border-radius: 10px; /* Optional: Adjust styling */
    overflow: hidden; /* Ensures image aligns properly */
  }
  
  
  .course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px #6a0dad;
  }
  
  .course-img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  
  .floating-animation {
    animation: floatUpDown 3s infinite ease-in-out alternate;
  }
  
  @keyframes floatUpDown {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  