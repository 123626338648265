/* Footer Styles */
.footer {
    background-color: #1a1a1a;
    color: white;
    padding: 40px 0;
    font-size: 1rem;
  }
  
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-section h5 {
    font-size: 1.2rem;
    color: #ffdd57;
    margin-bottom: 15px;
  }
  
  /* Footer Links Styling */
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 5px;
  }
  
  .footer-links a {
    color: #6a0dad;  /* Purple color */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-links a:hover {
    color: white;  /* Change to white on hover */
    text-decoration: underline;
  }
  
  /* Contact & Address Styling */
  .footer-section p {
    margin: 5px 0;
  }
  
  .footer-section a {
    color: #ffdd57;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-section a:hover {
    color: white;
    text-decoration: underline;
  }
  
  /* Footer Bottom Section */
  .footer-bottom {
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    text-align: center;
  }
  
  /* Social Media Icons */
  .social-icons {
    text-align: right;
  }
  
  .social-icons a {
    color: white;
    font-size: 1.5rem;
    margin-left: 15px;
    transition: color 0.3s ease-in-out;
  }
  
  .social-icons a:hover {
    color: #ffdd57;
  }
  