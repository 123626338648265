.internship-card {
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}

.internship-card:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.company-logo img {
  border-radius: 50%;
  object-fit: cover;
}

.skills {
  font-size: 14px;
  color: #555;
}

.apply-button {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .internship-card {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .internship-card {
    padding: 15px;
    text-align: center;
  }
}
