.dashboard-container {
    display: flex;
    height: 100vh;
    background: #f5f5f5;
  }
  
  /* ✅ Sidebar Styling */
  .sidebar {
    width: 250px;
    background: #2c3e50;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
  }
  
  .menu li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .menu li:hover,
  .menu li.active {
    background: #34495e;
  }
  
  /* ✅ Content Styling */
  .content {
    flex: 1;
    padding: 40px;
    background: white;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-initials {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #555;
    color: white;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .edit-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .save-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  