* {
  cursor: none; /* Hide default cursor */
}

.cursor-core {
  position: fixed;
  width: 15px;
  height: 13px;
  background: radial-gradient(circle, #ffffff, #6a0dad);
  border-radius: 50%;
  pointer-events: none;
  z-index: 10000;
  box-shadow: 0 10px 10px #6a0dad;
}

.cursor-trail {
  position: fixed;
  width: 30px;
  height: 30px;
  background: #6a0dad;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  filter: blur(4px);
}
