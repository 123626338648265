/* Hero Section */
.hero-section {
    background: linear-gradient(135deg, #6a0dad, #8a2be2);
    color: white;
    padding: 100px 20px;
    text-align: center;
    border-radius: 0 0 50px 50px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .hero-title span {
    color: #ffdd57;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .cta-button {
    margin: 15px;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    transform: scale(1.1);
  }
  
  /* Statistics Section */
  .stats-section {
    padding: 60px 20px;
    background: #f8f9fa;
  }
  
  .stat-card {
    border: none;
    border-radius: 15px;
    padding: 20px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .stat-icon {
    font-size: 3rem;
    color: #6a0dad;
    margin-bottom: 15px;
  }
  
  /* Testimonials Slider */
  .testimonials-section {
    padding: 60px 20px;
    background: white;
    text-align: center;
  }
  
  .testimonial-slide {
    padding: 20px;
  }
  
  .testimonial-text {
    font-size: 1.2rem;
    color: #555;
    font-style: italic;
  }
  
  .testimonial-name {
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .quote-icon {
    font-size: 3rem;
    color: #6a0dad;
  }
  