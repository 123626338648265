.lms-home {
    font-family: 'Arial', sans-serif;
  }
  
  .hero-section {
    padding: 60px 20px;
    background: linear-gradient(120deg, #6a0dad, #7958e7);
    color: white;
  }
  
  .features-section {
    padding: 40px 0;
    background-color: #f8f9fa;
  }
  
  .course-card {
    margin: 5px;
    transition: transform 0.2s ease-in-out;
  }
  
  .course-card:hover {
    transform: scale(1.05);
  }
  
  .why-us-section, .testimonials-section, .workshops-section, .community-section {
    padding: 40px 20px;
  }
  
  footer {
    background: #343a40;
    color: white;
  }
  
  .feature-card {
    padding: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background 0.3s;
  }
  
  .feature-card:hover {
    transform: scale(1.3);
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 6px 15px #6a0dad;
  }
  