.auth-container {
  height: 100vh;
  background: radial-gradient(circle, #6a0dad, #4e0a97);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-card {
  width: 420px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
  padding: 25px;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.auth-content {
  color: white;
}

.card {
  border-radius: 15px;
  padding: 20px;
  animation: pop-in 0.5s ease-out;
}

.toggle-link {
  color: #ffea00;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.toggle-link:hover {
  color: #ffc400;
}

@keyframes pop-in {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
