/* Contact Page Styling */
.contact-container {
    padding: 50px 0;
    background: #f9f9f9;
  }
  
  .contact-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #6a0dad;
    margin-bottom: 20px;
  }
  
  /* Contact Form */
  .contact-form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(106, 13, 173, 0.2);
    transition: all 0.3s ease-in-out;
  }
  
  .contact-form:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(106, 13, 173, 0.3);
  }
  
  /* Contact Button */
  .contact-button {
    background-color: #6a0dad !important;
    border: none;
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-button:hover {
    background-color: #5a0c99 !important;
    transform: scale(1.05);
  }
  