.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .not-found-icon {
    width: 500px;
    margin-bottom: 20px;
    position: relative;
    left: -5%;
  }
  
  .not-found-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #6a0dad;
  }
  
  .not-found-text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  